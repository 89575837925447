var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-modal',{attrs:{"title":"批量发送优惠券码","visible":_vm.visible,"confirmLoading":_vm.loading,"width":"1000px","centered":"","destroyOnClose":"","okText":"发送"},on:{"ok":_vm.onOk,"cancel":function($event){return _vm.$emit('cancel')}}},[_c('a-form',{staticClass:"coupon-send-detail",attrs:{"form":_vm.form}},[_c('a-form-item',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}]},[_c('div',{directives:[{name:"decorator",rawName:"v-decorator",value:(['id']),expression:"['id']"}]})]),_c('a-form-item',{attrs:{"label":"券码分类","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'category',
          {
            rules: [{ required: true, message: '请选择券码分类！' }]
          }
        ]),expression:"[\n          'category',\n          {\n            rules: [{ required: true, message: '请选择券码分类！' }]\n          }\n        ]"}],model:{value:(_vm.category),callback:function ($$v) {_vm.category=$$v},expression:"category"}},_vm._l((_vm.CouponCategory),function(value,key){return _c('a-radio',{key:key,attrs:{"value":key}},[_vm._v(" "+_vm._s(value)+" ")])}),1)],1),_c('a-form-item',{attrs:{"label":"站内信标题","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'title',
          {
            rules: [
              { required: true, message: '请填写站内信标题！' },
              { max: 80, message: '请输入不超过 80 个字符！' }
            ]
          }
        ]),expression:"[\n          'title',\n          {\n            rules: [\n              { required: true, message: '请填写站内信标题！' },\n              { max: 80, message: '请输入不超过 80 个字符！' }\n            ]\n          }\n        ]"}]})],1),_c('a-form-item',{attrs:{"label":"站内信内容","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol,"extra":_vm.extra}},[_c('v-editor',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'content',
          {
            initialValue: _vm.initialContent,
            rules: [
              { required: true, message: '请填写站内信内容！' },
              { max: 2000, message: '请输入不超过 2000 个字符！' }
            ]
          }
        ]),expression:"[\n          'content',\n          {\n            initialValue: initialContent,\n            rules: [\n              { required: true, message: '请填写站内信内容！' },\n              { max: 2000, message: '请输入不超过 2000 个字符！' }\n            ]\n          }\n        ]"}],staticClass:"editor",attrs:{"modules":{ toolbar: [['link', 'clean']] }}})],1),_c('a-form-item',{attrs:{"label":"领取用户与优惠券码","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_c('v-user-coupon-code',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'values',
          {
            initialValue: [],
            rules: [
              { required: true, message: '请填导入领取用户与优惠券码！' }
            ]
          }
        ]),expression:"[\n          'values',\n          {\n            initialValue: [],\n            rules: [\n              { required: true, message: '请填导入领取用户与优惠券码！' }\n            ]\n          }\n        ]"}]})],1),_c('a-form-item',{attrs:{"label":"导入领取用户与优惠券码","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_c('a-upload',{attrs:{"accept":"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet","customRequest":_vm.onUpload,"showUploadList":false}},[_c('a-button',{attrs:{"type":"primary","icon":"upload"}},[_vm._v(" 上传 ")])],1),_c('a-button',{staticStyle:{"marginLeft":"10px"},attrs:{"icon":"download"},on:{"click":_vm.onDownload}},[_vm._v(" 模板 ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }