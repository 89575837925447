



















































































































import Vue from "vue";
import moment from "moment";
import XLSX from "xlsx";
import { download, xlsx } from "@/utils";
import { dispatch, CouponCodeSend } from "@/store";
import { url } from "@/api";
import { CouponCodeSendErrorType, CouponCategory } from "@/enum";

export default Vue.extend({
  props: {
    visible: Boolean,
    id: Number
  },
  data() {
    return {
      moment,
      CouponCategory,
      loading: false,
      form: this.$form.createForm(this),
      labelCol: { span: 5 },
      wrapperCol: { span: 14 },
      category: ""
    };
  },
  computed: {
    isAdd() {
      return !this.id;
    },
    claimUrl(): string {
      switch (this.category) {
        case "special_offer":
          return url.mallCouponCodeClaim + "?code=${code}";
        case "early_bird":
          return url.mallAMCodeClaim + "?code=${code}";
        default:
          return "[请先选择优惠券分类]";
      }
    },
    initialContent(): string {
      return `站内信内容…… <a href="${this.claimUrl}">点击领取</a>，如果点击链接无法领取成功，请复制以下券码进入“个人中心” - “我的优惠券” 或 “必购码” 自助领取：\${code}`;
    },
    extra(): string {
      return `领取券码的链接为：${this.claimUrl} （必须在内容中设置此超链接，否则用户将无法领取优惠券）`;
    }
  },
  methods: {
    onUpload({ file }: { file: File }) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: "array" });
        const name = workbook.SheetNames[0];
        const sheet = workbook.Sheets[name];
        const values: any[] = [];
        let codeKey = "";
        let userKey = "";
        for (const key in sheet) {
          const { w } = sheet[key];
          if (w === "券码") {
            codeKey = key.replace("1", "");
          }
          if (w?.includes("客户ID") || w?.includes("用户ID")) {
            userKey = key.replace("1", "");
          }
          if (userKey && key.includes(userKey)) {
            const index = Number(key.replace(userKey, "")) - 2;
            if (index >= 0) {
              if (values[index]) {
                values[index].user_id = Number(w);
              } else {
                values[index] = {
                  user_id: Number(w),
                  coupon_activity_code: "",
                  others: {}
                };
              }
            }
          }
          if (codeKey && key.includes(codeKey)) {
            const index = Number(key.replace(codeKey, "")) - 2;
            if (index >= 0) {
              if (values[index]) {
                values[index].coupon_activity_code = w;
              } else {
                values[index] = {
                  user_id: "",
                  coupon_activity_code: w,
                  others: {}
                };
              }
            }
          }
        }
        const errorIndex = values.findIndex(
          item =>
            !item.user_id ||
            !item.coupon_activity_code ||
            typeof item.user_id !== "number"
        );
        if (errorIndex >= 0) {
          this.$message.error(`第 ${errorIndex + 2} 行数据有误，请仔细检查`);
          return;
        }
        const codes = new Set(values.map(item => item.coupon_activity_code));
        if (values.length > codes.size) {
          this.$message.error(`优惠券码重复，请仔细检查`);
          return;
        }
        this.form.setFieldsValue({ values });
        this.$message.success("导入成功");
      };
      reader.onerror = () => {
        this.$message.error("导入失败");
      };
      reader.readAsArrayBuffer(file);
    },
    onDownload() {
      const file = xlsx([
        {
          name: "领取用户与优惠券码导入模板",
          data: [["用户ID", "券码"]],
          cols: [{ wch: 10 }, { wch: 15 }]
        }
      ]);
      download(file, "领取用户与优惠券码导入模板.xlsx");
    },
    onOk() {
      this.$confirm({
        title: "请仔细核对数据，确认立即进行批量发送？",
        centered: true,
        onOk: this.onSubmit
      });
    },
    onSubmit() {
      if (this.loading) {
        return;
      }
      this.form.validateFieldsAndScroll(
        { scroll: { offsetTop: 200 } },
        (errors, values) => {
          if (!errors) {
            while (values.content.includes('target="_blank"')) {
              values.content = values.content.replace('target="_blank"', "");
            }
            const couponCodeSend: CouponCodeSend = {
              ...values
            };
            this.loading = true;
            if (this.isAdd) {
              dispatch
                .couponsPostCodeSend(couponCodeSend)
                .then(res => {
                  this.$message.success("消息已添加，正在发送……", 5000);
                  dispatch
                    .couponsSendCodeSend(res.id)
                    .then(res => {
                      const data = [
                        [
                          "券码",
                          "创建时间",
                          "是否成功发送",
                          "错误类型",
                          "错误信息"
                        ]
                      ];
                      res.results.map((item: any) => {
                        data.push([
                          item.coupon_activity_code,
                          moment(item.create_time).format(
                            "YYYY-MM-DD HH:mm:ss"
                          ),
                          item.is_success ? "是" : "否",
                          (CouponCodeSendErrorType as any)[item.error_type],
                          item.error_msg
                        ]);
                      });
                      const file = xlsx([
                        {
                          name: "批量发送结果",
                          data,
                          cols: [
                            { wch: 15 },
                            { wch: 30 },
                            { wch: 15 },
                            { wch: 15 },
                            { wch: 60 }
                          ]
                        }
                      ]);
                      this.$message.destroy();
                      this.$message.info("批量发送结果导出成功，正在下载…");
                      download(file, "批量发送结果.xlsx");
                      this.loading = false;
                      this.$emit("cancel");
                    })
                    .catch(() => {
                      this.loading = false;
                    });
                })
                .catch(() => {
                  this.loading = false;
                });
            } else {
              // dispatch
              //   .notificationsPatch(couponCodeSend)
              //   .then(() => {
              //     this.$message.success("消息已修改");
              //     this.loading = false;
              //   })
              //   .catch(() => {
              //     this.loading = false;
              //   });
            }
          }
        }
      );
    }
  },
  watch: {
    category() {
      this.form.setFieldsValue({ content: this.initialContent });
    }
  },
  mounted() {
    if (this.id) {
      this.loading = true;
      dispatch
        .couponsGetCodeSend(Number(this.id))
        .then((notification: Notification) => {
          this.form.setFieldsValue({
            ...notification
          });
          this.loading = false;
        });
    }
  }
});
