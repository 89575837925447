































































































import Vue from "vue";
import moment from "moment";
import { download, xlsx } from "@/utils";
import { dispatch, ListQuery } from "@/store";
import { CouponCodeSendErrorType } from "@/enum";
import CouponSendDetail from "./CouponSendDetail.vue";

export default Vue.extend({
  components: {
    CouponSendDetail
  },
  data() {
    return {
      dispatch,
      loading: false,
      form: this.$form.createForm(this),
      list: [],
      query: {
        ordering: "-id",
        page_number: 1,
        page_size: 10
      },
      count: 0,
      visible: false,
      sendVisible: false,
      sendList: []
    };
  },
  computed: {
    columns() {
      const columns: any = [
        {
          title: "站内信标题",
          key: "title",
          dataIndex: "title",
          width: "150px"
        },
        {
          title: "站内信内容",
          key: "content",
          dataIndex: "content",
          scopedSlots: { customRender: "content" }
        },
        {
          title: "创建时间",
          dataIndex: "create_time",
          scopedSlots: { customRender: "time" }
        },
        {
          title: "更新时间",
          dataIndex: "update_time",
          scopedSlots: { customRender: "time" }
        },
        {
          title: "操作",
          key: "actions",
          scopedSlots: { customRender: "actions" },
          width: "150px"
        }
      ];
      return columns;
    },
    sendColumns() {
      const columns: any = [
        {
          title: "券码",
          dataIndex: "coupon_activity_code",
          width: "150px"
        },
        {
          title: "用户 ID",
          dataIndex: "user_id"
        },
        {
          title: "发送时间",
          dataIndex: "create_time",
          scopedSlots: { customRender: "time" }
        },
        {
          title: "是否发送成功",
          dataIndex: "is_success",
          customRender: (text: string) => (text ? "是" : "否")
        },
        {
          title: "错误类型",
          dataIndex: "error_type",
          customRender: (text: string) => (CouponCodeSendErrorType as any)[text]
        },
        {
          title: "错误信息",
          dataIndex: "error_msg"
        }
      ];
      return columns;
    }
  },
  methods: {
    getList(query: ListQuery) {
      this.loading = true;
      dispatch
        .couponsGetCodeSendList(query)
        .then(res => {
          this.list = res.results;
          this.query = {
            ...query,
            page_number: res.page_number,
            page_size: res.page_size
          };
          this.count = res.count;
          this.loading = false;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    onFilterChange() {
      const filter = this.form.getFieldsValue();
      this.getList({
        ...this.query,
        ...filter,
        page_number: 1
      });
    },
    onFilterReset() {
      this.form.resetFields();
      this.onFilterChange();
    },
    onCancel() {
      this.visible = false;
      this.getList(this.query);
    },
    onSend(id: number) {
      this.$message.success("消息已添加，正在发送……", 5000);
      dispatch
        .couponsSendCodeSend(id)
        .then(res => {
          const data = [
            ["券码", "发送时间", "是否成功发送", "错误类型", "错误信息"]
          ];
          res.results.map((item: any) => {
            data.push([
              item.coupon_activity_code,
              moment(item.create_time).format("YYYY-MM-DD HH:mm:ss"),
              item.is_success ? "是" : "否",
              (CouponCodeSendErrorType as any)[item.error_type],
              item.error_msg
            ]);
          });
          const file = xlsx([
            {
              name: "批量发送结果",
              data,
              cols: [
                { wch: 15 },
                { wch: 30 },
                { wch: 15 },
                { wch: 15 },
                { wch: 60 }
              ]
            }
          ]);
          this.$message.destroy();
          this.$message.info("批量发送结果导出成功，正在下载…");
          download(file, "批量发送结果.xlsx");
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    onShowSend(id: number) {
      dispatch
        .couponsGetCodeSend(id)
        .then(res => {
          console.log(res);
          const sendList = res.values.map((item: any) => {
            const result = res.results[item.coupon_activity_code] || [];
            return {
              ...item,
              ...(result[0] || {})
            };
          });
          this.sendList = sendList;
          this.sendVisible = true;
        })
        .catch(() => {
          this.$message.destroy();
          this.$message.info("暂无发送信息");
        });
    },
    onExport() {
      const data = [
        ["券码", "用户 ID", "发送时间", "是否成功发送", "错误类型", "错误信息"]
      ];
      this.sendList.map((item: any) => {
        data.push([
          item.coupon_activity_code,
          item.user_id,
          moment(item.create_time).format("YYYY-MM-DD HH:mm:ss"),
          item.is_success ? "是" : "否",
          (CouponCodeSendErrorType as any)[item.error_type],
          item.error_msg
        ]);
      });
      const file = xlsx([
        {
          name: "批量发送结果",
          data,
          cols: [
            { wch: 15 },
            { wch: 15 },
            { wch: 30 },
            { wch: 15 },
            { wch: 15 },
            { wch: 60 }
          ]
        }
      ]);
      this.$message.destroy();
      this.$message.info("批量发送结果导出成功，正在下载…");
      download(file, "批量发送结果.xlsx");
      this.loading = false;
    },
    onSendCancel() {
      this.sendVisible = false;
      this.getList(this.query);
    },
    onTableChange(page: any, filters: any, sorter: any) {
      let ordering =
        sorter.order === "ascend" ? "-" + (sorter.columnKey || "") : "";
      ordering = sorter.order === "descend" ? sorter.columnKey || "" : ordering;
      this.getList({
        ...this.query,
        page_number: page.current,
        page_size: page.pageSize,
        ordering: ordering || this.query.ordering
      });
    }
  },
  mounted() {
    this.getList(this.query);
  }
});
